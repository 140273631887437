import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import MKTypography from "muikit-components/MKTypography";

import DefaultNavbar from "components/DefaultNavbar";
import CenteredFooter from "components/CenteredFooter";

import Testimonials from "sections/Testimonials";
import Advantages from "sections/Advantages";
import Services from "sections/Services";

import routes from "routes";

import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import bgImage2 from "assets/images/photo.png";
import Teachers from "sections/Teachers";
import ContactForm from "sections/ContactForm";

const ScrollToSection = ({ hash, sectionRefs }) => {
  useEffect(() => {
    if (hash && sectionRefs[hash]) {
      sectionRefs[hash].current.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash, sectionRefs]);

  return null;
};

function Homepage() {
  const servicesRef = useRef(null);
  const teachersRef = useRef(null);
  const advantagesRef = useRef(null);
  const contactFormRef = useRef(null);
  const testimonialsRef = useRef(null);

  const sectionRefs = {
    "#services": servicesRef,
    "#teachers": teachersRef,
    "#advantages": advantagesRef,
    "#contact": contactFormRef,
    "#testimonials": testimonialsRef,
  };

  const [selectedTeacher, setSelectedTeacher] = React.useState("");
  const location = useLocation();

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "info",
        }}
        relative
      />
      <Grid container item lg={12} justifyContent="center" alignItems="center" direction="row">
        <Grid container item lg={6} alignItems="center" direction="column">
          <MKTypography
            fontFamily="Atomosfer"
            variant="h1"
            color="info"
            mb={1}
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            Science School{" "}
          </MKTypography>
          <MKTypography
            variant="body1"
            textAlign="center"
            color="info"
            px={{ xs: 7, lg: 12 }}
            mt={1}
          >
            Школа природничих наук
          </MKTypography>
        </Grid>
        <img height="300vh" src={bgImage2} alt="..." />
      </Grid>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 0,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <div ref={servicesRef}>
          <Services />
        </div>
        <Divider sx={{ my: 2 }} />
        <div ref={teachersRef}>
          <Teachers setSelectedTeacher={setSelectedTeacher} />
        </div>
        <Divider sx={{ my: 2 }} />
        <div ref={advantagesRef}>
          <Advantages />
        </div>
        <Divider sx={{ my: 2 }} />
        <div ref={contactFormRef}>
          <ContactForm selectedTeacher={selectedTeacher} setSelectedTeacher={setSelectedTeacher} />
        </div>
        <Divider sx={{ my: 2 }} />
        <div ref={testimonialsRef}>
          <Testimonials />
        </div>
      </Card>
      <CenteredFooter />
      <ScrollToSection hash={location.hash} sectionRefs={sectionRefs} />
    </>
  );
}

export default Homepage;
