import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import SchoolIcon from "@mui/icons-material/School";
import Person2Icon from "@mui/icons-material/Person2";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReviewsIcon from "@mui/icons-material/Reviews";
import { Navigate } from "react-router-dom";

const routes = [
  {
    name: "Про нас",
    icon: <SchoolIcon />,
    route: "/#services",
    component: <Navigate to="/#services" />,
  },
  {
    name: "Викладачі",
    icon: <Person2Icon />,
    route: "/#teachers",
    component: <Navigate to="/#teachers" />,
  },
  {
    name: "Переваги",
    icon: <FormatListBulletedIcon />,
    route: "/#advantages",
    component: <Navigate to="/#advantages" />,
  },
  {
    name: "Запис",
    icon: <ChatBubbleOutlineIcon />,
    route: "/#contact",
    component: <Navigate to="/#contact" />,
  },
  {
    name: "Відгуки",
    icon: <ReviewsIcon />,
    route: "/#testimonials",
    component: <Navigate to="/#testimonials" />,
  },
];

export default routes;
