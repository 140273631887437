import React from "react";
import Slider from "react-slick";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import MKBox from "muikit-components/MKBox";
import MKTypography from "muikit-components/MKTypography";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonials.css";

import rev1 from "assets/rev1.jpg";
import rev2 from "assets/rev2.jpg";
import rev3 from "assets/rev3.jpg";
import rev4 from "assets/rev4.jpg";
import rev5 from "assets/rev5.jpg";
import rev6 from "assets/rev6.jpg";
import rev7 from "assets/rev7.jpg";
import rev8 from "assets/rev8.jpg";
import rev9 from "assets/rev9.jpg";
import rev10 from "assets/rev10.jpg";
import rev11 from "assets/rev11.jpg";
import rev12 from "assets/rev12.jpg";
import rev13 from "assets/rev13.jpg";
import rev14 from "assets/rev14.jpg";
import rev15 from "assets/rev15.jpg";
import rev16 from "assets/rev16.jpg";
import rev17 from "assets/rev17.jpg";

function Testimonials() {
  const testimonials = [
    rev1,
    rev2,
    rev3,
    rev4,
    rev5,
    rev6,
    rev7,
    rev8,
    rev9,
    rev10,
    rev11,
    rev12,
    rev13,
    rev14,
    rev15,
    rev16,
    rev17,
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <MKTypography variant="h4" color="info">
          Відгуки
        </MKTypography>
      </Grid>
      <MKBox component="section" sx={{ padding: 3 }}>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <Grid item key={index} sx={{ padding: 1 }}>
              <img
                src={testimonial}
                alt={`testimonial-${index}`}
                style={{ width: "100%", maxWidth: "600px" }}
              />
            </Grid>
          ))}
        </Slider>
      </MKBox>
    </Container>
  );
}

export default Testimonials;
