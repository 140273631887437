import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKTypography from "muikit-components/MKTypography";

import adv1 from "assets/adv1.gif";
import adv2 from "assets/adv2.gif";
import adv3 from "assets/adv3.jpg";
import adv4 from "assets/adv4.png";
import adv5 from "assets/adv5.jpg";
import adv6 from "assets/adv6.jpg";

function AdvatageItem({ title, image }) {
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <MKTypography variant="h5" color="dark">
          {title}
        </MKTypography>
      </Grid>
      <Grid item xs={12}>
        <img
          src={image}
          alt="presentation"
          style={{
            width: "100%",
            maxWidth: "600px",
            height: "auto",
          }}
        />
      </Grid>
    </Grid>
  );
}

function Advantages() {
  const advantages = [
    {
      image: adv1,
      title: "Якісні навчальні матеріали",
    },
    {
      image: adv3,
      title: "Багато практики",
    },
    {
      image: adv4,
      title: "Високі результати учнів НМТ 2024",
    },
    {
      image: adv2,
      title: "Інтерактив",
    },
    {
      image: adv5,
      title: "Підтримка і дружня атмосфера",
    },
    {
      image: adv6,
      title: "Відслідковування прогресу",
      isSmall: true,
    },
  ];

  return (
    <Container>
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ mb: 4 }}>
        <MKTypography variant="h4" color="info">
          Наші переваги
        </MKTypography>
      </Grid>
      <Grid container direction="row" alignItems="center" spacing={3}>
        {advantages.map(({ image, title }, index) => {
          return (
            <Grid item key={index} xs={12} md={6}>
              <AdvatageItem image={image} title={title} />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

export default Advantages;
