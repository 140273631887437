import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import MKBox from "muikit-components/MKBox";
import MKTypography from "muikit-components/MKTypography";

import service1 from "assets/service_1.svg";
import service2 from "assets/service_2.svg";
import service3 from "assets/service_3.svg";
import service4 from "assets/service_4.svg";

import { useState } from "react";

function ServiceCard({ title, description, icon, imgWidth }) {
  const [cardColor, setCardColor] = useState("white");

  return (
    <Card
      onMouseOver={() => {
        setCardColor("#ececec");
      }}
      onMouseOut={() => {
        setCardColor("white");
      }}
      sx={{
        p: 2,
        mx: { xs: 2, lg: 3 },
        mt: 12,
        mb: 4,
        background: cardColor,
        boxShadow: ({ boxShadows: { xxl } }) => xxl,
      }}
    >
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <MKBox
          width="10rem"
          height="10rem"
          bgColor="opaque"
          opacity={1}
          alignItems="center"
          justifyContent="center"
          display="flex"
          position="relative"
          mt={-12}
          sx={{
            overflow: "hidden",
          }}
        >
          <img width={imgWidth} src={icon} alt="test" />
        </MKBox>
        <Grid container direction="column" alignItems="center" p={2}>
          <Grid container alignItems="center" justifyContent="center" sx={{ minHeight: "70px" }}>
            <MKTypography align="center" variant="h5" color="dark">
              {title}
            </MKTypography>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Divider
              sx={{
                borderWidth: 5,
                width: 150,
                opacity: "1",
                background: "#5F70B7",
              }}
            />
          </Grid>
        </Grid>
        <MKTypography variant="body2" color="text">
          {description}
        </MKTypography>
      </Grid>
    </Card>
  );
}

function Services() {
  const services = [
    {
      title: "Індивідуальні уроки",
      description:
        "Проведення занять 1-на-1 з викладачем, складання індивідуальної програми стратегії",
      imgWidth: "150",
      icon: service1,
    },
    {
      title: "Уроки в міні-групі",
      description:
        "Проведення занять в групі з 4-6 людей з викладачем, всі уроки в записі до кінця навчання, індивідуальна перевірка домашніх завдань і раз на 3 місяці індивідуальна зустріч з директором Яною для контролю пройденого матеріалу",
      icon: service2,
      imgWidth: "150",
    },
    {
      title: "Навчання студентів",
      description:
        "Проведення заняття зі студентами за університетською програмою з медичних, біологічних та хімічних дисциплін.",
      imgWidth: "220",
      icon: service3,
    },
    {
      title: "Інтенсивне навчання",
      description:
        "Коротка програма для інтенсивної підготовки або повторення матеріалу, рекомендується перед екзаменом або співбесідою. Навчання йде за індивідуальною програмою під конкретного учня",
      imgWidth: "190",
      icon: service4,
    },
  ];

  return (
    <MKBox component="section">
      <Container>
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <MKTypography variant="h4" color="info">
            Наші послуги
          </MKTypography>
        </Grid>
        <Container>
          <Grid container direction="row" alignItems="stretch">
            {services.map((service, index) => {
              return (
                <Grid item key={index} xs={12} md={6} lg={3} style={{ display: "flex" }}>
                  <ServiceCard
                    title={service.title}
                    description={service.description}
                    icon={service.icon}
                    imgWidth={service.imgWidth}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Container>
    </MKBox>
  );
}

export default Services;
